import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import config from '../../../config/index.json';
import Edit from './Edit';

const Item = ({ children, item, showEditButton = true }) => {
	// TODO useConfig hook which returns only needed values
	const { items: { message: { charLimit } } } = config;
	const [toggleText, setToggleText] = React.useState(true);
	const [showToggleButton, setShowToggleButton] = React.useState(false);
	const [isEditMode, setIsEditMode] = React.useState(false);

	React.useEffect(() => {
		if (item.message.length > charLimit) {
			setShowToggleButton(true);
			setToggleText(true);
		} else {
			setShowToggleButton(false);
			setToggleText(false);
		}
	}, [charLimit, item.message.length]);

	const handleToggleText = () => {
		setToggleText(!toggleText);
	};

	const handleEdit = () => {
		setIsEditMode(!isEditMode);
	};

	return (
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<Card variant="outlined">
				<CardContent>
					<Grid container rowSpacing={1} columnSpacing={1} sx={{ alignItems: 'center' }}>
						<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
							<Typography variant="h5" component="div">
								{item.iteration ?? item.index}. {item.author.name}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ textAlign: 'right' }}>
							<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
								Datum: {new Date(item.created).toLocaleString("de-DE", {})}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							{isEditMode ? (
								<Edit item={item} handleEdit={handleEdit} />
							) : (
								<Typography variant="body2">
									{toggleText ? `${item.message.substring(0, charLimit)}...` : item.message}
								</Typography>
							)}
						</Grid>
					</Grid>
				</CardContent>

				{children || (
					<CardActions>
						<Grid container rowSpacing={1} columnSpacing={1} sx={{ justifyContent: 'flex-end' }}>
							{showToggleButton && (
								<Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
									<Button variant="outlined" sx={{ display: 'flex', width: '100%' }} onClick={handleToggleText}>
										{toggleText ? 'Mehr anzeigen' : 'Weniger anzeigen'}
									</Button>
								</Grid>
							)}

							{showEditButton && (
								<Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
									<Button variant="contained" sx={{ display: 'flex', width: '100%' }} onClick={handleEdit}>
										Edit
									</Button>
								</Grid>
							)}
						</Grid>
					</CardActions>
				)}
			</Card >
		</Grid >
	);
};

export default Item;