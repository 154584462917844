import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

const Layout = ({ h1, children }) => {
	return (
		<Container>
			<Grid container rowSpacing={5}>
				{h1 && (
					<Grid item sx={{ textAlign: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant="h4" component="h1">
							{h1}
						</Typography>
					</Grid>
				)}

				{children}
			</Grid>
		</Container>
	);
};

export default Layout;