import React from 'react';

const useStatus = () => {
	const [status, setStatus] = React.useState('loading');

	return {
		status,
		setStatus
	}
};

export default useStatus;