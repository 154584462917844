import React from 'react';
import useInterval from './useInterval';

const useCountdown = (time, callback, delay) => {
	const callbackRef = React.useRef(callback);
	const [timeLeft, setTimeLeft] = React.useState(time);

	// Don't schedule if no delay is specified.
	// Note: 0 is a valid value for delay.
	if (!Number(delay)) {
		throw Error('The value given in "delay" needs to be a number.');
	}

	const tick = React.useCallback(() => {
		setTimeLeft((prevState) => prevState - 1);
	}, []);
	const { intervalClear } = useInterval(tick, delay);

	// Remember the latest callback if it changes.
	React.useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	// Clear countdown if it reaches 0
	React.useEffect(() => {
		if (timeLeft === 0) {
			intervalClear();

			callbackRef.current();
		}
	}, [intervalClear, timeLeft]);

	// Execute on unmount
	React.useEffect(() => {
		return () => {
			intervalClear()
		};
	}, [intervalClear]);

	return {
		clearCountdown: intervalClear,
		timeLeft
	}
};

export default useCountdown;

// React.useEffect(() => console.log("mount"), [] );
// React.useEffect(() => console.log("data1 update"), [ data1 ] );
// React.useEffect(() => console.log("any update") );
// React.useEffect(() => () => console.log("data1 update or unmount"), [ data1 ] );
// React.useEffect(() => () => console.log("unmount"), [] );

// React.useEffect(() => {
// 	console.log('Always');
// });

// React.useEffect(() => {
// 	console.log('Mounted');
// }, []);

// React.useEffect(() => {
// 	console.log('Mounted / Updated');

// 	return () => {
// 		console.log('Update cleanup');
// 	};
// }, [timeLeft]);