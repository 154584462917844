import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Language = () => {
	const { locale } = useParams();
	const navigate = useNavigate();

	const activeProps = {
		variant: "contained"
	};
	const inActiveProps = {
		variant: "outlined"
	};

	const handleLocaleChange = (locale) => {
		navigate(`/${locale}`);
	};

	return (
		<>
			<Grid container rowSpacing={2.5} columnSpacing={2.5} sx={{ justifyContent: 'center' }}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant="h6" component="h6">
						Sprache wählen:
					</Typography>
				</Grid>

				<Grid item xs={6} sm={6} md="auto" lg="auto" xl="auto">
					<Button onClick={() => handleLocaleChange('de')} {...(locale === 'de' ? activeProps : inActiveProps)}>
						Deutsch
					</Button>
				</Grid>

				<Grid item xs={6} sm={6} md="auto" lg="auto" xl="auto">
					<Button onClick={() => handleLocaleChange('en')} {...(locale === 'en' ? activeProps : inActiveProps)}>
						Englisch
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default Language;