import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { updateDocument } from '../../../../db/firestore';

const Edit = ({ item, handleEdit }) => {
	const [message, setMessage] = React.useState(item.message);

	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	const handleSubmit = () => {
		updateDocument({
			data: {
				message
			},
			id: item.id,
		})
			.then(() => {
				handleEdit();
			})
			.catch((error) => {
				throw new Error(error.message);
			});
	};

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
		>
			<Grid container rowSpacing={2} columnSpacing={2} sx={{ alignItems: 'center' }}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<TextField
						fullWidth={true}
						multiline
						id="outlined-multiline-static"
						value={message}
						onChange={handleChange}
						rows={6}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container rowSpacing={1} columnSpacing={1} sx={{ justifyContent: 'flex-end' }}>
						<Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
							<Button variant="contained" sx={{ display: 'flex', width: '100%' }} onClick={handleEdit}>Abbrechen</Button>
						</Grid>

						<Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
							<Button variant="contained" sx={{ display: 'flex', width: '100%' }} onClick={handleSubmit}>Speichern</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Edit;